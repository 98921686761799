.header {
	max-width: 80%;
	margin: 0 auto;
	padding: 35px 0px;
	h2 {
		text-align: center;
		text-transform: uppercase;
		margin: auto;
		padding: 0px 15px;
		max-width: 1000px;
		font-size: 2.1rem;
		line-height: 1.2;
	}
	p {
		margin: auto;
		margin-bottom: 5px;
		margin-top: 5px;
		font-size: 1rem;
		line-height: 1.2;
	}
}

.header-before {
	&::before {
		content: '';
		display: block;
		margin: 0;
		width: 125px;
		height: 25px;
		border-top: 7.5px solid $primary-color;
		border-left: 7.5px solid $primary-color;
		border-right: 7.5px solid $primary-color;
		margin: 0 auto 15px;
	}
}

.header-before-left {
	&::before {
		content: '';
		display: block;
		margin: 0;
		width: 90px;
		height: 20px;
		border-top: 7.5px solid $primary-color;
		border-left: 7.5px solid $primary-color;
		border-right: 7.5px solid $primary-color;
		margin: 15px 0;
	}
}

.header-after {
	&::after {
		content: '';
		display: block;
		margin: 15px auto;
		width: 125px;
		height: 25px;
		border-bottom: 7.5px solid $primary-color;
		border-left: 7.5px solid $primary-color;
		border-right: 7.5px solid $primary-color;
	}

	h2 {
		text-align: center;
		text-transform: uppercase;
	}
}

.pre-section {
	background-color: #e3e3e3;
	padding: 14px 0;
	justify-content: center;
}

.sections {
	h2 {
		font-size: 47px;
		text-transform: uppercase;
	}
	h2::before {
		content: '';
		display: block;
		margin: 0;
		width: 85px;
		height: 17px;
		border-top: 7.5px solid $primary-color;
		border-left: 7.5px solid $primary-color;
		border-right: 7.5px solid $primary-color;
	}
	h6 {
		font-family: 'AvenirLTStd-Light';
		font-size: 17.5px;
	}
	h4 {
		font-family: 'AvenirLTStd-Light';
		font-size: 23px;
	}
	h5 {
		margin-bottom: 0;
	}
	p,
	li {
		font-size: 13px;
		margin-bottom: 5px;
		text-align: justify;
	}

	li:before {
		content: '- ';
	}
	.text-container {
		padding: 30px 44px;
	}
	.image-container {
		padding: 0;
		display: flex;
		img {
			width: 100%;
		}

		@media (min-width: 768px) and (max-width: 991px) {
			img {
				object-fit: contain;
			}
		}
	}
	span {
		color: grey;
	}
}

.slider {
	padding-top: 27px;

	img {
		width: 100%;
		height: 475px;
		object-fit: cover;
	}
}

.range-details {
	padding-bottom: 50px;
	justify-content: space-around;

	.range-tables {
		padding-top: 15px;

		span {
			color: grey;
		}
	}
}

.spacing {
	margin-top: 30px;
}

.primary-button {
	background-color: $primary-color;
	color: #ffffff;
	font-family: AvenirLTStd-Black;
	font-size: 16px;
	font-weight: 400;
	text-transform: uppercase;
	padding: 12px 20px;
	border: transparent;
	cursor: pointer;
}

.jumbotron {
	width: 100vw;
	height: 550px;
	img {
		object-fit: cover;
		height: 100%;
		width: 100%;
	}
}
.jumbotron-big {
	min-height: 100vh;
}

.flex-2-div {
	display: flex;
	flex-wrap: wrap;
	.side {
		width: 50%;
	}
	.text-side {
		padding: 35px;
		padding-top: 50px;
		padding-bottom: 100px;
		h2 {
			margin-bottom: 15px;
			span {
				display: block;
				font-size: 1.5rem;
			}
		}
		p {
			line-height: 1.8;
			width: 100%;
			overflow-x: hidden;
		}
	}
	.img-side {
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
}

.home-contact {
	width: 100%;
	background-color: $gray-background;
	padding: 0 15px;
	padding-bottom: 45px;
	text-align: center;
	h2 {
		opacity: 0;
	}
}

@media screen and (max-width: 991px) {
	.flex-2-div {
		.side {
			width: 100%;
		}
		.text-side {
			order: 1;
		}
		.img-side {
			order: 2;
			height: 300px;
		}
	}
	.header {
		max-width: 100%;
		margin: 0 auto;
		padding: 35px 0px;
		h2 {
			text-align: center;
			text-transform: uppercase;
			margin: auto;
			padding: 0px 15px;
			max-width: 1000px;
			font-size: 1.5rem;
			line-height: 1.2;
		}
		p {
			width: 350px;
			margin: auto;
			margin-bottom: 5px;
			margin-top: 5px;
			font-size: 1.2rem;
			line-height: 1.2;
		}
	}
}

.centered-content {
	text-align: center;
}
