.hidden {
	visibility: hidden;
	opacity: 0;
	transition: visibility 0s linear 1200ms, opacity 1200ms;
}

.visible {
	visibility: visible;
	opacity: 1;
	transition: visibility 0s linear 0s, opacity 1200ms;
}

.animOut {
	transition: all 1s ease-out;
	transform: translateY(2vh);
	-webkit-transform: translateY(2vh);
	opacity: 0;
}

.animIn {
	transition: all 1s ease-out !important;
	transform: translateY(0%) !important;
	-webkit-transform: translateY(0%) !important;
	opacity: 1 !important;
}
