.contact-page {
	.contact-section {
		width: 100%;
		background: url('../assets/images/about/fond.jpg');
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center center;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		align-items: center;
		padding: 50px 20px;
		margin: 0;
		h2 {
			text-transform: uppercase;
			margin-bottom: 15px;
			text-align: center;
		}
		p {
			max-width: 800px;
			margin: auto;
			line-height: 1.8;
			text-align: center;
		}
	}
	.contact-infos {
		padding-top: 20px;
		.bold-paragraph {
			font-weight: 800;
		}
		p {
			font-size: 1.1rem;
			line-height: 1.3;
		}
	}

	.form {
		min-height: 100vh;
		max-width: 1200px;
		padding: 50px 15px;
		margin: auto;
		text-align: center;
		.horaire {
			margin-bottom: 100px;
			margin-top: 30px;
		}
		.group {
			width: 100%;
			display: flex;
			align-items: flex-start;
			justify-content: space-between;
			.group-label {
				margin-top: 33px;
				border: 1px solid black;
				width: min-content;
				padding: 15px;
				width: 18%;
			}
			.group-inputs {
				width: 85.5%;
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				margin: auto;

				.input-select-container {
					position: relative;
					width: 100%;
					max-width: 480px;
					display: flex;
					flex-direction: column;
					align-items: flex-start;
					margin: 15px 0;
					&::before {
						content: '';
						position: absolute;
						bottom: 0;
						left: 50%;
						transform: translateX(-50%);
						height: 3px;
						width: 0;
						background-color: #719dff;
						transition: 0.3s;
					}

					h6 {
						margin-bottom: 5px;
						text-transform: uppercase;
						font-weight: bold;
						transform: translateY(25px);
						transition: 0.3s;
						pointer-events: none;
					}
					&:focus-within {
						&::before {
							width: 100%;
						}
						h6 {
							transform: translate(-17%, 0) scale(0.7);
							color: #719dff;
						}
					}
					.filled {
						transform: translate(-17%, 0) scale(0.7);
					}
				}
				input,
				select,
				textarea {
					width: 100%;
					max-width: 100%;
					padding: 6px;
					outline: 0;
					border: 0;
					border-bottom: 2px solid black;
					font-size: 1.2rem;
					box-sizing: border-box;
					background: white;
					option {
						background: white;
					}
				}
				input[type='file'] {
					font-size: 0.8rem;
				}
				.fullWidth {
					max-width: 100%;
				}
			}
		}
	}
	.apres {
		text-align: center;
		background-color: $gray-background;
		padding: 70px 15px;
	}
}

@media screen and (max-width: 1180px) {
	.contact-page {
		.form {
			.group {
				.group-inputs {
					.input-select-container {
						max-width: none;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 850px) {
	.contact-page {
		.form {
			.group {
				.group-inputs {
					.input-select-container {
						max-width: none;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 700px) {
	.contact-page {
		.form {
			.group {
				.group-label {
					display: none;
				}
				.group-inputs {
					width: 100%;
					.input-select-container {
						max-width: none;
					}
				}
			}
		}
	}
}
