html,
body {
  font-family: 'AvenirLTStd-Light', 'sans-serif';
  font-size: 18px;
}

main {
  position: relative;
  width: 100%;
  min-height: 100vh;
}

.row-reverse {
  flex-direction: row-reverse;
}

.mat-form-field {
  width: 100%;
}

.text-center {
  text-align: center;
}

.xlarg-container {
  padding: 0;
  position: relative;
  z-index: 1;
}

h2 {
  font-family: 'AvenirLTStd-Light';
  font-size: 50px;
}

h5 {
  font-family: 'AvenirLTStd-Black';
  font-size: 13px;
}

p {
  font-family: 'AvenirLTStd-Book';
  font-size: 16px;
}

.top-scroll {
  position: fixed;
  bottom: 5px;
  right: 5%;
  z-index: 1000;
  width: 50px;
  height: 50px;
  -webkit-transform: translatez(0);
  -moz-transform: translatez(0);
  -o-transform: translatez(0);
  -ms-transform: translatez(0);
  transform: translatez(0);
}

.top-scroll a {
  cursor: pointer;
  display: block;
  height: 50px;
  background: url(/assets/images/arrow.svg) rgb(14, 126, 177) center center;
}

.top-scroll a:hover {
  background: url(/assets/images/arrow.svg) rgba(124, 203, 252, 0.6) center
    center;
}

.mat-dialog-actions {
  float: right;
}
