@font-face {
  font-family: 'AvenirLTStd-Light';
  src: url('../assets/fonts/avenirLTStd-Light/AvenirLTStd-Light.eot?#iefix')
      format('embedded-opentype'),
    url('../assets/fonts/avenirLTStd-Light/AvenirLTStd-Light.otf')
      format('opentype'),
    url('../assets/fonts/avenirLTStd-Light/AvenirLTStd-Light.woff')
      format('woff'),
    url('../assets/fonts/avenirLTStd-Light/AvenirLTStd-Light.ttf')
      format('truetype'),
    url('../assets/fonts/avenirLTStd-Light/AvenirLTStd-Light.svg#AvenirLTStd-Light')
      format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'AvenirLTStd-Black';
  src: url('../assets/fonts/AvenirLTStd-Black/AvenirLTStd-Black.eot?#iefix')
      format('embedded-opentype'),
    url('../assets/fonts/AvenirLTStd-Black/AvenirLTStd-Black.otf')
      format('opentype'),
    url('../assets/fonts/AvenirLTStd-Black/AvenirLTStd-Black.woff')
      format('woff'),
    url('../assets/fonts/AvenirLTStd-Black/AvenirLTStd-Black.ttf')
      format('truetype'),
    url('../assets/fonts/AvenirLTStd-Black/AvenirLTStd-Black.svg#AvenirLTStd-Black')
      format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'AvenirLTStd-Book';
  src: url('../assets/fonts/AvenirLTStd-Book/AvenirLTStd-Book.eot?#iefix')
      format('embedded-opentype'),
    url('../assets/fonts/AvenirLTStd-Book/AvenirLTStd-Book.otf')
      format('opentype'),
    url('../assets/fonts/AvenirLTStd-Book/AvenirLTStd-Book.woff') format('woff'),
    url('../assets/fonts/AvenirLTStd-Book/AvenirLTStd-Book.ttf')
      format('truetype'),
    url('../assets/fonts/AvenirLTStd-Book/AvenirLTStd-Book.svg#AvenirLTStd-Book')
      format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'AvenirLTStd-Heavy';
  src: url('../assets/fonts/AvenirLTStd-Heavy/AvenirLTStd-Heavy.eot?#iefix')
      format('embedded-opentype'),
    url('../assets/fonts/AvenirLTStd-Heavy/AvenirLTStd-Heavy.otf')
      format('opentype'),
    url('../assets/fonts/AvenirLTStd-Heavy/AvenirLTStd-Heavy.woff')
      format('woff'),
    url('../assets/fonts/AvenirLTStd-Heavy/AvenirLTStd-Heavy.ttf')
      format('truetype'),
    url('../assets/fonts/AvenirLTStd-Heavy/AvenirLTStd-Heavy.svg#AvenirLTStd-Heavy')
      format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'AvenirLTStd-Roman';
  src: url('../assets/fonts/AvenirLTStd-Roman/AvenirLTStd-Roman.eot?#iefix')
      format('embedded-opentype'),
    url('../assets/fonts/AvenirLTStd-Roman/AvenirLTStd-Roman.otf')
      format('opentype'),
    url('../assets/fonts/AvenirLTStd-Roman/AvenirLTStd-Roman.woff')
      format('woff'),
    url('../assets/fonts/AvenirLTStd-Roman/AvenirLTStd-Roman.ttf')
      format('truetype'),
    url('../assets/fonts/AvenirLTStd-Roman/AvenirLTStd-Roman.svg#AvenirLTStd-Roman')
      format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir-Book';
  src: url('../assets/fonts/Avenir-Book/Avenir-Book.eot?#iefix')
      format('embedded-opentype'),
    url('../assets/fonts/Avenir-Book/Avenir-Book.woff') format('woff'),
    url('../assets/fonts/Avenir-Book/Avenir-Book.ttf') format('truetype'),
    url('../assets/fonts/Avenir-Book/Avenir-Book.svg#Avenir-Book') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir-Heavy';
  src: url('../assets/fonts/Avenir-Heavy/Avenir-Heavy.eot?#iefix')
      format('embedded-opentype'),
    url('../assets/fonts/Avenir-Heavy/Avenir-Heavy.woff') format('woff'),
    url('../assets/fonts/Avenir-Heavy/Avenir-Heavy.ttf') format('truetype'),
    url('../assets/fonts/Avenir-Heavy/Avenir-Heavy.svg#Avenir-Heavy')
      format('svg');
  font-weight: normal;
  font-style: normal;
}
