.slideshow {
	width: 100%;
	position: relative;
	aspect-ratio: 3/1;
	.carousel-indicators {
		display: flex;
		position: absolute;
		bottom: 20px;
		left: 50%;
		transform: translateX(-50%);
		z-index: 2;
		div {
			cursor: pointer;
			width: 30px;
			height: 30px;
			border-radius: 35px;
			background-color: white;
			border-width: 3px;
			border-style: solid;
			border-color: $secondary-color;
			z-index: 2;
			margin: 2px;
		}
	}

	.carousel-indicators .active {
		background-color: $primary-color;
	}
	.carousel-inner {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		overflow: hidden;
	}
	.carousel-img {
		height: fit-content;
		width: 100%;
		object-fit: cover;
	}
}

@media (max-width: 873px) {
	.slideshow {
		width: 100%;
		position: relative;
		height: 520px;
		.carousel-img {
			height: 520px;
			object-fit: cover;
		}
	}
}
