.about-page {
	.about-section {
		width: 100%;
		background: url('../assets/images/about/fond.jpg');
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center center;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 50px 20px;
		margin: 0;
		h2 {
			text-transform: uppercase;
			margin-bottom: 15px;
			text-align: center;
		}
		p {
			max-width: 800px;
			margin: auto;
			line-height: 1.8;
			text-align: center;
		}
	}
}
