.career-page {
	.career-section {
		width: 100%;
		background: url('../assets/images/about/fond.jpg');
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center center;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		align-items: center;
		padding: 50px 20px;
		margin: 0;
		h2 {
			text-transform: uppercase;
			margin-bottom: 15px;
			text-align: center;
		}
		p {
			max-width: 800px;
			margin: auto;
			line-height: 1.8;
			text-align: center;
		}
	}
	.career-infos {
		padding-top: 20px;
		.bold-paragraph {
			font-weight: 800;
		}
		p {
			font-size: 1.1rem;
			line-height: 1.3;
			text-transform: capitalize;
		}
	}

	.apres {
		text-align: center;
		background-color: $gray-background;
		padding: 70px 15px;
	}
}

@media screen and (max-width: 1180px) {
	.career-page {
		.form {
			.group {
				.group-inputs {
					.input-select-container {
						max-width: none;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 850px) {
	.career-page {
		.form {
			.group {
				.group-inputs {
					.input-select-container {
						max-width: none;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 700px) {
	.career-page {
		.form {
			.group {
				.group-label {
					display: none;
				}
				.group-inputs {
					width: 100%;
					.input-select-container {
						max-width: none;
					}
				}
			}
		}
	}
}
