header {
	position: fixed;
	top: 0px;
	left: 0px;
	z-index: 16;
	width: 100%;
	display: block;
	font-family: 'AvenirLTStd-Black';
	font-size: 13px;
	background-color: #343a4069;
	height: 136px;
	display: flex;
	flex-direction: column;
	transition: height 0.5s;

	.language-bar {
		background: #1a1719;
		color: white;
		display: inline-block;
		width: 100%;
		height: 30px;
		padding: 5px;
		position: relative;
		z-index: 100;

		p {
			float: right;
			margin: 4px 10px;
			cursor: pointer;
			font-size: 13px;

			span {
				margin-left: 3px;
				color: $secondary-color;
				text-transform: uppercase;
			}
		}

		.dropdown-menu {
			position: absolute;
			top: 28px;
			right: 20px;
			height: 0;
			display: none;
			transition: all 1s;
			background: #1a1719;
			min-width: initial;
			width: 100px;
			overflow: hidden;

			a.dropdown-item {
				color: white;
				font-size: 13px;
				text-transform: capitalize;
				padding: 5px 0px;
				line-height: 19.5px;
				height: 100%;
				width: 100% !important;
				text-align: center;
			}

			a.dropdown-item:hover {
				background: $secondary-color;
				color: #4e4c4d;
			}
		}
		.show-dropdown {
			height: 78px;
			padding: 5px 0;
			display: flex;
			flex-direction: column;
			align-items: center;
			transition: all 1s;
		}
	}

	.header-menu {
		color: white;
		display: flex;
		align-items: center;
		width: 100%;
		height: 100%;

		@media (min-width: 1180px) {
			.nav-bar-menu {
				display: none !important;
			}
		}

		.nav-bar-menu {
			position: absolute;
			width: 100%;
			top: 0;
			padding: 0;
			.mobile-btn {
				position: absolute;
				top: 48px;
				right: 20px;
				padding: 15px;
				border: 3px solid $secondary-color;
				background-color: #fff;
			}

			> div {
				margin-top: 100px;
				padding: 0 10px;

				li a {
					font-size: 16px;
					text-transform: uppercase;
					font-family: 'AvenirLTStd-Black';
					color: #4e4c4d !important;
				}

				li a:after {
					filter: invert(75%);
				}

				li > a {
					padding-left: 5px;
				}
			}
		}

		.menu {
			display: inline-block;
			padding: 0;
			margin: 0;
			margin-top: 10px;
			position: relative;
			cursor: pointer;
			width: 100% !important;

			li {
				float: left;
				font-weight: 500;
				margin-right: 20px;
				text-transform: uppercase;
				position: relative;
				padding: 5px;
			}

			.dropdown .dropdown-menu {
				position: absolute;
				left: 0;
				top: 25px;
				padding: 10px 0;
				height: max-content;
				width: max-content;
				display: flex;
				flex-direction: column;
				align-items: center;
			}

			.dropdown .dropdown-menu li {
				width: 100% !important;
				margin: 0px;
				padding: 7px 20px;
				display: flex;
				flex-direction: column;
				a {
					color: #fff !important;
					font-size: 13px;
					text-transform: uppercase;
					height: 100%;
					width: 100% !important;
				}
			}

			.dropdown .dropdown-menu li:hover {
				transition: background 0.5s;
				background: $secondary-color;
			}

			a {
				font-size: 14px;
				font-weight: 500;
				color: white;
				padding: 5px;
			}
		}

		.dropdown .dropdown-menu {
			transition: all 0.5s;
			margin-top: 0;
			max-height: 0;
			overflow: hidden;
			opacity: 0;
		}

		@media (min-width: 1180px) {
			.dropdown .dropdown-menu {
				background-color: #343a40a8;
			}
		}

		.dropdown:hover .dropdown-menu {
			max-height: unset;
			opacity: 1;
		}

		.custom-dropdown-position {
			left: -50px;
		}
		.right-menu {
			margin-left: auto;
			display: flex;
			justify-content: center;
			width: max-content;
			a {
				color: #fff !important;
				font-size: 13px;
				text-transform: uppercase;
			}
		}
	}
	.dropdown-toggle {
		text-transform: uppercase;
	}
	.dropdown-toggle::after {
		background: url(../assets/images/menu-dropdown.png) center no-repeat;
		background-size: contain;
		content: '';
		display: inline-block;
		width: 9px;
		height: 10px;
		border: initial;
		margin-left: 12px;
		vertical-align: 0.155em;
	}

	.dropdown-toggle-link::after {
		display: none;
	}

	.dropdown-toggle-link-active {
		border: 4px solid $primary-color;
	}

	.logo {
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		align-self: flex-start;
		margin: 0 19px;
		width: 180px;
		height: 120%;
		transition: height, width 1s;
		background-color: white;
		opacity: 0.8;

		img {
			width: 120%;
			height: auto;
		}
	}

	.active-link {
		outline: 3px solid $secondary-color;
	}

	.active-sub-link {
		background: $secondary-color !important;
	}

	.mobile-menu {
		display: none;
	}

	.toggle-mobile-menu-button {
		display: none;
	}

	@media (max-width: 1179px) {
		.toggle-mobile-menu-button {
			display: block;
			margin: 15px;
			margin-left: auto;
			padding: 15px;
			border: 2px solid $primary-color;
			border-radius: 5px;
			background: white;
			span {
				background: url('../assets/mobileNavSandwitch.svg');
			}
		}
		.mobile-menu {
			z-index: 101;
			position: absolute;
			top: -1000%;
			left: 0;
			right: 0;
			width: 100vw;
			background-color: #f8f9fa !important;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: flex-start;
			color: rgb(41, 40, 40);
			transition: all 0.2s ease-in;
			.mobile-menu-links {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: flex-start;
				width: 100%;
				.dropdown {
					padding: 20px 15px;
					font-size: 1rem;
					width: 100%;

					a,
					span {
						color: rgb(41, 40, 40);
						margin-bottom: 7px;
						padding: 15px 15px;
					}
				}
				.dropdown-menu {
					padding: 10px;
					border: 2px solid black;
					border-radius: 2px;
					width: 100%;
					li {
						padding: 15px 5px;
						a {
							padding: 5px;
						}
					}
				}
				.dropdown .dropdown-menu {
					transition: all 0.5s;
					margin-top: 0;
					max-height: 0;
					min-height: 0;
					overflow: hidden;
					opacity: 0;
					margin: 0;
					padding: 0;
				}

				.dropdown:hover .dropdown-menu {
					max-height: none;
					opacity: 1;
					margin-top: 15px;
					margin-bottom: 15px;
					padding: 15px;
				}
			}
		}
		.mobile-menu-open {
			top: 30px;
			transition: all 0.2s ease-out;
		}
	}
}

.white-menu {
	transition: height 0.5s;
	color: #4e4c4d !important;
	box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.25);
	height: 100px;
}

@media (max-width: 1179px) {
	header .header-menu .menu {
		display: none;
	}

	.white-menu {
		height: 122px;
	}
}

.header-fixed {
	height: 116px;
	.header-menu {
		.logo {
			width: 150px;
			height: 100%;
			transition: height, width 1s;
			background-color: transparent;
			opacity: 1;
		}
	}
}
