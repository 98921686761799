.footer {
	background-color: #212529;
	color: white;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	.footer-part {
		padding-bottom: 15px;
		padding-left: 45px;
		width: 45%;
		.footer-info {
			position: relative;
			background-color: #212529;
			padding: 25px 10px 5px 50px;
			display: flex;
			width: 100%;
			.footer-logo {
				margin-top: 50px;
				margin-bottom: 10px;
				width: 400px;
				img {
					width: 100%;
					height: 100%;
				}
			}
			.suivez-nous-box {
				position: absolute;
				width: 350px;
				left: calc(100% - 200px);
				display: flex;
				justify-content: flex-end;
				align-items: flex-start;

				.legend {
					display: flex;
					position: relative;
					max-width: 100%;
					margin-left: 115px;
					padding: 12px 20px;
					background-color: $primary-color;
					font-family: 'AvenirLTStd-Black';
					font-size: 18px;
					line-height: 1.15em;
					justify-content: center;
					color: #fff;
				}

				.legend::after {
					position: absolute;
					right: -20px;
					top: 4px;
					border: 20px solid transparent;
					border-bottom-color: $primary-color;
					content: '';
				}
			}
		}
		.footer-description {
			padding-left: 30px;
			p {
				font-family: 'AvenirLTStd-book';
				font-size: 13px;
				margin-bottom: 0;
				color: #fff;
				line-height: 19.5px;
			}
			ul {
				li {
					font-size: 13px;
					margin: 10px 0;
				}
				li::before {
					content: '-    ';
				}
			}
		}

		.social-share {
			display: flex;
			justify-content: space-around;
			flex-wrap: wrap;
			border-bottom: 1px solid #ffffff73;
			padding: 35px 10px 5px 10px;

			a {
				padding-bottom: 10px;
				cursor: pointer;
				width: 75px;
				img {
					width: 100%;
				}
				img:hover {
					filter: brightness(120%);
					width: 100%;
				}
			}
		}

		.footer-links {
			padding: 15px 0px;
			font-size: 12px;
			color: #fff;
			display: flex;
			flex-wrap: wrap;
			align-items: flex-start;
			padding-right: 20px;
			flex-direction: column;
			height: 200px;
			.footer-links-div {
				padding: 0 3px;

				margin-bottom: 15px;
				line-height: 19.5px;
				.big-link {
					font-family: 'AvenirLTStd-Black';
					text-transform: uppercase;
					color: white;
				}

				ul {
					margin-bottom: 3px;
					margin-top: 14px;
					li::before {
						content: '>    ';
					}

					a {
						font-family: 'AvenirLTStd-book';
						font-size: 13px;
						font-weight: 200;
						margin-bottom: 0;
						color: rgb(250, 250, 250);
						line-height: 19.5px;
						text-transform: capitalize;
					}
				}
			}
		}
	}
	.copyright {
		width: 100vw;
		background: #000;
		color: white;
		font-family: 'AvenirLTStd-book';
		font-size: 13px;
		padding: 20px 0 20px 40px;
		text-transform: uppercase;
	}
}

@media (max-width: 700px) {
	.footer {
		flex-wrap: wrap;
		justify-content: space-between;
		.footer-part {
			padding-bottom: 15px;
			padding-right: 15px;
			padding-left: 15px;
			width: 100%;
			.footer-info {
				padding: 10px;
				.footer-logo {
					margin-top: 50px;
					margin-bottom: 10px;
					width: 200px;
					img {
						width: 100%;
					}
				}
				.suivez-nous-box {
					display: none;
				}
			}
			.footer-description {
				padding-left: 0;
			}

			.social-share {
				padding: 35px 10px 5px 10px;
			}
		}
	}
}

@media (max-width: 575px) {
}
