.home-page {
	min-height: 300vh;
	.home-features {
		width: 100%;
		background-color: white;
		padding: 10px 15px 25px;
		.header-after {
			padding-bottom: 0;
		}
		.feature-images {
			width: 100%;
			padding: 15px;
			padding-bottom: 40px;
			padding-top: 2px;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-around;
			align-items: flex-start;
			.one-feature {
				text-align: center;
				margin-top:30px;
				p {
					margin-top: 15px;
					font-weight: bold;
					width: 200px;
					text-align: center;
					font-size:14px;
				}
			}
		}
	}

	.bg-switcher {
		position: fixed;
		left: 0;
		right: 0;
		top: 0;
		z-index: -1;
		width: 100vw;
		min-height: 100vh;
		.bg-container {
			width: 100%;
			height: 100%;
			overflow: hidden;
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			opacity: 0.3;
			-webkit-transition: opacity 1s ease-in-out;
			-moz-transition: opacity 1s ease-in-out;
			-o-transition: opacity 1s ease-in-out;
			transition: visibility 0s linear 0s, opacity 1s linear;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
		.bg-hidden {
			animation: fadeOut 1s forwards linear;
		}
		.bg-visible {
			animation: fadeIn 1s forwards linear;
		}
	}
	.sommaire {
		background: white;
		justify-content: center;
	}

	.home-pre-section {
		padding: 20px 0px 45px;
		text-align: center;
		background-color: #fff;

		img {
			margin-bottom: 12px;
		}
		p {
			font-family: 'AvenirLTStd-Black';
			font-size: 13px;
			margin-bottom: 0;
			text-transform: uppercase;
		}
	}
	.dynamic-tag {
		width: 70%;
	}
}

.section-text-image {
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding-left: 45px !important;
	margin-top: 20px;
	margin-bottom: 20px;
	min-height: 100vh;

	h2 {
		font-family: 'AvenirLTStd-Heavy';
	}
	p {
		font-family: 'AvenirLTStd-Heavy';
	}

	.constraintBox {
		display: flex;
		width: 100%;
		position: relative;
	}

	@media (min-width: 1024px) {
		.constraintBox {
			width: 80%;
			max-width: 930px;
		}
	}
	.framedText {
		padding: 10px 0;
		margin: 2px 0 0px 59px;
		h2 {
			width: 100%;
			text-transform: uppercase;
			color: #fff;
			font-size: 2rem;
			margin-bottom: 20px;
		}
		p {
			width: 100%;
			color: #fff;
			margin-bottom: 35px;
			line-height: 1.5;
		}
		button {
			font-size: 12px;
			font-weight: bold;
			border: 4px solid $secondary-color;
			width: 182px;
			height: 45px;
			text-align: center;
			text-transform: uppercase;
		}
	}
	.framedIcon {
		widows: 86px;
		position: relative;
		display: flex;
		flex-direction: column;

		img {
			margin: 20px 0;

			margin-left: -2px;
			height: 100px;
			width: 100px;
			flex-shrink: 0;
		}
	}
	.framedIcon:before {
		background: $secondary-color;
		content: '';
		width: 6px;
		display: block;
		height: 100%;
		margin-left: 42px;
	}
	.framedIcon::after {
		background: $secondary-color;
		content: '';
		width: 6px;
		display: block;
		height: 100%;
		margin-left: 42px;
	}
}

.section-text-image::before {
	content: '';
	display: block;
	height: 8px;
	width: 194px;
	border-right: 5pt solid $secondary-color;
	border-left: 5pt solid $secondary-color;
	border-top: 5pt solid $secondary-color;
	margin-left: 42px;
}

.section-text-image::after {
	content: '';
	display: block;
	height: 19px;
	width: 194px;
	border-right: 5pt solid $secondary-color;
	border-left: 5pt solid $secondary-color;
	border-bottom: 5pt solid $secondary-color;
	margin-left: 42px;
}

@media (max-width: 1023px) {
	home .dynamic-tag {
		width: 80%;
	}
}

@media (max-width: 482px) {
	.section-text-image {
		padding-left: 5px !important;
		.framedText {
			margin: 2px 0 0px 15px;
		}
	}
	section-text-image::after {
		width: 146px;
	}
	section-text-image::before {
		width: 146px;
	}
}

@media (max-width: 380px) {
	.section-text-image h2 {
		font-size: 22px;
	}
	.section-text-image .framedText button {
		display: none;
	}
}

@media (max-width: 782px) {
	.home-page {
		.dynamic-tag {
			width: 100%;
			padding: 15px;
		}
	}
	.section-text-image {
		padding-left: 0;
	}
}

@keyframes fadeIn {
	to {
		opacity: 1;
	}
}
@keyframes fadeOut {
	to {
		opacity: 0;
	}
}
