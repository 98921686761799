.products-page {
	box-sizing: border-box;
	width: 100vw;
	.products-section {
		width: 100%;
		background: url('../assets/images/about/fond.jpg');
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center center;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 50px 20px;
		margin: 0;
		h2 {
			text-transform: uppercase;
			margin-bottom: 15px;
			text-align: center;
		}
		p {
			max-width: 800px;
			margin: auto;
			line-height: 1.8;
			text-align: center;
		}
	}

	.filter-container {
		text-align: center;
		padding: 5px 15px;
		padding-bottom: 25px;
		margin: auto;
		overflow-x: hidden;
		.filter-by-big-title {
			width: max-content;
			text-align: center;
			font-size: 1.5rem;
			margin: 30px auto;
			padding: 5px;
			color: white;
			background-color: $primary-color;
		}

		.letter-bubbles-container {
			display: flex;
			justify-content: center;
			flex-wrap: wrap;
			align-items: center;
			margin-bottom: 15px;
			overflow-x: auto;
			padding: 10px;
			width: auto;
			&::-webkit-scrollbar {
				width: 0; /* Remove scrollbar space */
			}
			.filter-letter-bubble {
				display: flex;
				justify-content: center;
				align-items: center;
				padding: 4px;
				width: 35px;
				height: 35px;
				min-width: 35px;
				min-height: 35px;
				margin-right: 7px;
				margin-bottom: 7px;
				border-radius: 50%;
				font-size: 1.2rem;
				font-weight: bold;
				cursor: pointer;
			}
			.filter-letter-bubble-active {
				background-color: $secondary-color;
				border: 1px solid $primary-color;
			}
		}

		.categories {
			display: flex;
			justify-content: center;
			align-items: flex-end;
			flex-wrap: wrap;
			overflow-y: hidden;

			.category {
				width: 350px;
				height: 38px;
				padding: 10px;
				padding-right: 30px;
				margin-bottom: 5px;
				text-align: left;
				border-bottom: 1px dashed $primary-color;
				text-transform: capitalize;
				font-size: 1.2rem;
				margin-right: 35px;
				position: relative;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				cursor: pointer;
			}
			.plus {
				&::after {
					content: '+';
					color: #c8234f;
					position: absolute;
					right: 0;
					top: 50%;
					transform: translateY(-50%);
				}
			}
		}
	}
	.pink-divider {
		height: 7px;
		width: 100vw;
		min-width: 100vw;
		background: $primary-color;
	}

	.sub-categories-container {
		padding: 15px 15px;
		padding-bottom: 25px;
		margin: auto;
		overflow-x: hidden;
		.sub-categories-big-title {
			font-weight: bold;
			text-transform: capitalize;
			margin: 15px 0px;
			padding: 5px;
			display: flex;
			align-items: center;
		}
		.sub-categories {
			display: flex;
			justify-content: center;
			align-items: flex-start;
			flex-wrap: wrap;
			overflow-y: hidden;
			.sub-category {
				width: 400px;
				padding: 20px;
				text-align: left;
				text-transform: capitalize;
				font-size: 1.1rem;
				line-height: 1.5;
				position: relative;
				&::before {
					position: absolute;
					left: -10px;
					content: '>';
					color: $primary-color;
					margin-right: 5px;
					font-weight: bolder;
				}
			}
		}
	}
}
.subHeader {
	display: flex;
	align-items: center;
	justify-content: space-between;
	button {
		margin: 0 30px;
		border: 0;
		border-radius: 2px;
		font-size: 35px;
		background: $primary-color;
		color: white;
		padding: 10px;
		width: 40px;
		display: grid;
		place-items: center;
		transform: rotate(90deg);
		cursor: pointer;
	}
}
